import RcTableViewUtils from "../../../../../../shared/table/RcTableViewUtils";

import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow.js";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow.js";
import SpacerDisplayRow from "@/portals/shared/cell/spacer/SpacerDisplayRow.js";

import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow.js";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow.js";
// import StringUpdateRow from "@/portals/shared/cell/string/StringUpdateRow.js";
// import StringDisplayRow from "@/portals/shared/cell/string/StringDisplayRow.js";
import LabelValueDisplayRow from "@/portals/shared/cell/label/LabelValueDisplayRow.js";
// import ChecklistItemDisplayRow from "@/portals/shared/cell/checklist/item/ChecklistItemDisplayRow.js";
// import ChecklistItemOptionDisplayRow from "@/portals/shared/cell/checklist/item/option/ChecklistItemOptionDisplayRow.js";

import Checklist from '@/domain/model/checklist/CheckList.js';
// import AddViewUtils from "../add/AddViewUtils"

import MC from "@/domain/session/MC.js";

import StringUtils from '@/utils/StringUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';

export default class DisplayViewUtils extends RcTableViewUtils {
  
  constructor(model, tableData) {
    super(model, tableData);
    this.MC = new MC();
    this._checklist = model.checklist();
    this.SU = StringUtils;
    this.CU = ContentUtils;
  }

  checklist() {
    return this._checklist;
  }
  
  start() {
    super.start();
    if (this.model().isStatePageLoading()) {
      super.start("");
      return;
    }

    this.renderHeader();

    if (this.model().isStateContentLoading()) {
      super.start("Loading Checklist Properties");
      return;
    }

    if (this.model().isStateContentLoaded()) {
      this.renderBody();
    }

    this.renderFooter();
  } 

  renderBody() {
    this.addBody(new TitleDisplayRow()
        .withTitle("Checklist Details").withSubtitle(true)
        );
    this.addBody(new LabelValueDisplayRow()
                          .withWidth(5)
                          .withName(this.MC.CheckList.Add.NameLabel.value())
                          .withObject(Checklist.MODEL_NAME)
                          .withId(this.checklist().id())
                          .withField(Checklist.Fields.Name)
                          .withKey(Checklist.Fields.Name)
                          .withValue(this.checklist().name()));
                        
    this.addBody(new LabelValueDisplayRow()
                          .withWidth(10)
                          .withName(this.MC.CheckList.Add.DescriptionLabel.value())
                          .withObject(Checklist.MODEL_NAME)
                          .withValue(this.checklist().description())
                          .withId(this.checklist().id())
                          .withField(Checklist.Fields.Description)
                          .withKey(Checklist.Fields.Description));
    
    this.addBody(new TitleDisplayRow()
        .withTitle(this.MC.CheckList.Add.CheckListItemTitle.value())
        .withSubtitle(true)
        );
    
    this.createItemChildren();
  }

  renderHeader() {
    this.addHeader(new TitleDisplayRow().withTitle("Checklist Details: " + this.checklist().name()));
  }

  renderFooter() {
    
    this.addFooter(
      new RowDisplayRow()
      .withBorder(false)
      .withFixedPosition(true)
      .withChildren([

        new ButtonDisplayRow()
          .withWidth(3)
          .withName("Back")
          .withField("Close"),

        new SpacerDisplayRow()
          .withWidth(12),
      ])
    );
  }

  getChecklistItems() {
    return this.checklist().items();
  }
  
  createItemChildren() {
    var itemMap = this.getChecklistItems();
    var items = itemMap.sortByIndex();
    for (var i = 0 ; i < items.length; i++) {
      var item = items[i];
      
      this.log().info("{0} {1}", [item.index(), item.name()]);
      
      let label = StringUtils.format("{0}.", [item.index() + 1])
      this.addBody(new LabelValueDisplayRow().withLabelWidth(1).withName(label).withValue(item.name()))
      this.createItemOptionChildren(item);
    }
  }
  
  getChecklistItemOptions(item) {
    return item.options();
  }
  
  createItemOptionChildren(item) {
    var optionMap = this.getChecklistItemOptions(item);
    var options = optionMap.sortByIndex();
    
    var boxRow = new BoxDisplayRow();
    for (var i = 0 ; i < options.length; i++) {
      var option = options[i];
      var labelRow = new LabelValueDisplayRow();
      let label = StringUtils.format("{0}.", [option.index() + 1])
      labelRow.withName(label).withLabelWidth(1)
      labelRow.withValue(option.name())
      boxRow.withChild(labelRow);
    }
    var row = new RowDisplayRow();
    row
      .withChild(new SpacerDisplayRow().withWidth(2))
      .withChild(boxRow.withWidth(10))
    this.addBody(row);
  }
}