
import AddViewModel from "../add/AddViewModel";
import UpdateViewUtils from "./UpdateViewUtils";

export default class UpdateViewModel extends AddViewModel {
    
  constructor(panel, checklist) {
    super(panel, checklist);
  }

  start() {
    super.start();
  }

  withValue(valueData) {
    super.withValue(valueData);
  }

  withButtonPressed(buttonData) {
    super.withButtonPressed(buttonData);
  }

  buildRowData(tableData) {
    var utils = new UpdateViewUtils(this, tableData);
    utils.start();
    return this;
  }

}