<template>
  <AddView 
    :checklistId="checklistId" 
    @click="withButtonPressed" 
    @withButtonPressed="withButtonPressed" 
    @withValue="withValue"
    :isAdmin="isAdmin"></AddView>
</template>

<script>
import AddView from './AddView.vue';
export default {
  name: 'checklist-list-add-row',
  components: {
    AddView
  },
  props: {
    checklistId: { type: String, default: "" },
    isAdmin:     { type: Boolean, default: false },
  },
  methods: {
    withValue: function(event) {
      this.$emit('withValue', event);
    },
    withButtonPressed: function(event) {
      this.$emit('withButtonPressed', event);
    },
  }
}
</script>
