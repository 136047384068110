<template>
  <DisplayView 
    :checklistId="checklistId" 
    @click="withButtonPressed" 
    @withButtonPressed="withButtonPressed" 
    @withValue="withValue"
    :isAdmin="isAdmin"></DisplayView>
</template>

<script>
import DisplayView from './DisplayView.vue';
export default {
  name: 'checklist-list-display-row',
  components: {
    DisplayView
  },
  props: {
    checklistId: { type: String, default: "" },
    isAdmin:     { type: Boolean, default: false },
  },
  methods: {
    withValue: function(event) {
      this.$emit('withValue', event);
    },
    withButtonPressed: function(event) {
      this.$emit('withButtonPressed', event);
    },
  }
}
</script>
