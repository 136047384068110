import RcTableViewUtils from "../../../../../shared/table/RcTableViewUtils";
import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow";
import StringDisplayRow from "@/portals/shared/cell//string/StringDisplayRow";
import StringUpdateRow from "@/portals/shared/cell/string/StringUpdateRow.js";
import StringListUpdateRow from "@/portals/shared/cell/string/list/StringListUpdateRow.js";
import LabelValueDisplayRow from "@/portals/shared/cell//label/LabelValueDisplayRow";
import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";
import SpacerDisplayRow from "@/portals/shared/cell/spacer/SpacerDisplayRow";
// import StringUtils from '@/utils/StringUtils.js';
// import StatusDisplayRow from "@/portals/shared/cell/status/StatusDisplayRow.js";
//import DateUpdateRow from "@/portals/shared/cell/date/DateUpdateRow.js";

import MC from "@/domain/session/MC.js";
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

export default class ListViewUtils extends RcTableViewUtils {

  constructor(model, tableData) {
    super(model, tableData);
    this.MC = new MC();
    this.pages = [];
    this.Strings = new StringUtils();
  }

  start() {
    if (this.model().hasError()) {
      this.renderError();  
    }

    if (this.model().isStatePageLoading()) {
      super.start();
      return;
    }

    this.renderHeader(this.model().totalItems());

    if (this.model().isStateContentLoading()) {
      super.start();
      return;
    }

    if (this.model().isStateContentLoaded()) {
      this.renderBody();
    }

    this.renderFooter(this.model().totalItems());
  }

  renderBody() {
    var box = new BoxDisplayRow()
      .withBorder(true);
    box
      .withChild(new RowDisplayRow().withFixedPosition(true)
        .withBackgroundTitle()
        .withChild(new TitleDisplayRow().withWidth("5").withTitle("Details"))
        .withChild(new TitleDisplayRow().withWidth("5").withTitle(""))
        .withChild(new TitleDisplayRow().withWidth("5").withTitle(""))
      );

    var boxContent = new BoxDisplayRow()
    var even = true;

    var list = this.model().checklists();
    for (var index = 0; index < list.length; index++) {
      var checklist = list[index];
      if (checklist) {
        //
      }
      var checklistRow = new RowDisplayRow();
      checklistRow.withChild(new BoxDisplayRow().withWidth(5).withChild(new LabelValueDisplayRow().withLabel("Name").withValue(checklist.name())))
      checklistRow.withChild(new BoxDisplayRow().withWidth(5).withChild(new LabelValueDisplayRow().withLabel("Description").withValue(checklist.description())))
      checklistRow.withChild(new BoxDisplayRow().withWidth(5).withChild(new LabelValueDisplayRow().withLabel("Questions").withValue(checklist.items().size())))

      var actionsRow = new RowDisplayRow();
      actionsRow
        .withChild(new SpacerDisplayRow().withWidth("11"))
        .withChild(new ButtonDisplayRow().withWidth("2").withName("Details").withId(checklist.id()).withField("Details"))
        ;

      actionsRow
        .withChild(new ButtonDisplayRow().withWidth("2").withName("Update").withId(checklist.id()).withField("Update"))
        ;
      var buttonsBox = new BoxDisplayRow();
      buttonsBox.withChild(actionsRow);

      const body = new BoxDisplayRow()
            .withBorder(true)
            .withEven(even);

      body.withChild(checklistRow);

      body.withChild(buttonsBox);

      boxContent
        .withBorder(false)
        .withChild(body);

      even = !even;
    }

    

    box.withChild(boxContent).done();
    this.add(box);
  }

  renderFooter(totalItems) {
    var query = this.model().panel().$route.query;
    this.pages = this.model().pages();
    this.add(
        new BoxDisplayRow()
        .withBorder(true)
        .withWidth("15")
        .withChild(new RowDisplayRow()
          .withChild(new BoxDisplayRow()
            .withWidth("15")
            .withBorder(true)
            .withChild(new RowDisplayRow()
              .withChild(new LabelValueDisplayRow().withWidth("4").withLabel("Showing").withValue(this.createPageInfo(totalItems, query)))
              .withChild(new StringDisplayRow().withWidth("7"))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("<").withField("PreviousPage"))
              .withChild(new StringListUpdateRow().withWidth("2")
                                                  .withShowLabel(false)
                                                  .withName("Jump to Page")
                                                  .withField("PageIndex")
                                                  .withDefaultValue(query["page"] ? query["page"] : "1")
                                                  .withOptions(this.pages))
              .withChild(new ButtonDisplayRow().withWidth("1").withName(">").withField("NextPage"))
            )
          )
        )
    );
  }

  renderHeader(totalItems) {
    var query = this.model().panel().$route.query;
    this.add(
      new RowDisplayRow()
      .withChild(
        new BoxDisplayRow()
        .withBorder(true)
        .withWidth("15")
        .withChild(new RowDisplayRow()
          .withChild(new LabelValueDisplayRow().withWidth("1").withLabel("Filters"))
          .withChild(new SpacerDisplayRow().withWidth("10"))
          .withChild(new ButtonDisplayRow().withWidth("3").withName("Add Checklist").withField("Add").withBackgroundPrimaryAction())
          .withChild(new ButtonDisplayRow().withWidth("1").withName("Refresh").withField("Refresh"))
        )
        .withChild(new RowDisplayRow()
          .withChild(
            new BoxDisplayRow()
            .withWidth("15")
            .withBorder(true)
            .withChild(new RowDisplayRow().withBorder(false)
              .withChild(new LabelValueDisplayRow().withWidth("1").withLabel("Search"))
              .withChild(new StringUpdateRow().withWidth("5")
                  .withShowLabel(false).withField("Search")
                  .withKey("Search").withName("Search")
                  .withDefaultValue(query["search"] ? query["search"] : ""))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("Go").withKey("Search").withField("Search").withSelected(false))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("Clear").withKey("Search").withField("Clear").withSelected(false))
              .withChild(new SpacerDisplayRow().withWidth("7"))
            )
          )
        )
        .withChild(new RowDisplayRow()
          .withChild(new BoxDisplayRow()
            .withWidth("15")
            .withBorder(true)
            .withChild(new RowDisplayRow()
              .withChild(new LabelValueDisplayRow().withWidth("4").withLabelWidth("5").withLabel("Showing").withValue(this.createPageInfo(totalItems, query)))
              .withChild(new StringDisplayRow().withWidth("4"))
              .withChild(new StringListUpdateRow().withWidth("3")
                                                  .withName("Items/Page")
                                                  .withField("PageSize")
                                                  .withShowLabel(false)
                                                  .withDefaultValue(this.model().pageSize(query))
                                                  .withOptions(ContentUtils.PAGE_SIZES()))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("<").withField("PreviousPage"))
              .withChild(new StringListUpdateRow().withWidth("2")
                                                  .withShowLabel(false)
                                                  .withName("Jump to Page")
                                                  .withField("PageIndex")
                                                  .withDefaultValue(query["page"] ? query["page"] : "1")
                                                  .withOptions(ContentUtils.PAGE_INDEXES(totalItems, this.model().pageSize(query))))
              .withChild(new ButtonDisplayRow().withWidth("1").withName(">").withField("NextPage"))
            )
          )
        )
      )
    );

    // if (!user.isNew()) {
    //   var box = new BoxDisplayRow().withBorder(true);
    //   var title = StringUtils.format("Timesheets for {0}", [user.fullName()]);
    //   box.withChild(new RowDisplayRow()
    //       .withBackgroundTitle()
    //       .withChild(new TitleDisplayRow().withWidth("15").withTitle(title))
    //     );
    //   this.add(box);
    // }
  }

  makeAndModel(vehicle) {
    var value = "";
    var makeSet = false;
    if (StringUtils.isNotEmpty(vehicle.make())) {
      value = vehicle.make();
      makeSet = true;
    }
    if (StringUtils.isNotEmpty(vehicle.model())) {
      if (makeSet) {
        value = value + " / " + vehicle.model();
      } else {
        value = "<Not Set>" + "/ " + vehicle.model();
      }
    } else {
      if (makeSet) {
        value = value + " / " + "<Not Set>"
      }
    }
    return value;
  }
  
  categoryName(vehicle) {
    var category = vehicle.category().find();
    var list = ContentUtils.CATEGORIES();
    for (var i = 0; i < list.length; i++) {
      var item = list[i];
      if (item['@rid'] == category.id()) {
        return item['name'];
      }
    }
    return category.name();
  }
}