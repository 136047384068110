import RcTableViewUtils from "../../../../../../shared/table/RcTableViewUtils";

import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow.js";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow.js";
import SpacerDisplayRow from "@/portals/shared/cell/spacer/SpacerDisplayRow.js";

import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow.js";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow.js";
//import BoolUpdateRow from "@/portals/shared/cell/bool/BoolUpdateRow.js";
import StringUpdateRow from "@/portals/shared/cell/string/StringUpdateRow.js";
import StringDisplayRow from "@/portals/shared/cell/string/StringDisplayRow.js";
import TextAreaUpdateRow from "@/portals/shared/cell/textarea/TextAreaUpdateRow.js";
//import ChecklistItem from "@/domain/model/checklist/item/CheckListItem.js";
import ChecklistItemUpdateRow from "@/portals/shared/cell/checklist/item/ChecklistItemUpdateRow.js";
import ChecklistItemOptionUpdateRow from "@/portals/shared/cell/checklist/item/option/ChecklistItemOptionUpdateRow.js";

import Checklist from '@/domain/model/checklist/CheckList.js';

import MC from "@/domain/session/MC.js";

import StringUtils from '@/utils/StringUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';

export default class AddViewUtils extends RcTableViewUtils {
  
  static Fields = {
    NAME: "checklistName",
    DESCRIPTION: "checklistDescription",
    SaveButton: "saveButton",
    CancelButton: "cancelButton",
    
    AddQuestionButton: "addQuestionButton",
    RemoveQuestionButton: "removeQuestionButton",

    UpQuestionButton: "upQuestionButton",
    DownQuestionButton: "downQuestionButton",

    AddOptionButton: "addOptionButton",
    RemoveOptionButton: "removeOptionButton",
    
    UpOptionButton: "upOptionButton",
    DownOptionButton: "downOptionButton",

    QuestionBox: "addQuestionBox",
  }

  constructor(model, tableData) {
    super(model, tableData);
    this.MC = new MC();
    this._checklist = model.checklist();
    this.SU = StringUtils;
    this.CU = ContentUtils;
  }

  checklist() {
    return this._checklist;
  }
  
  start() {
    super.start();
    if (this.model().isStatePageLoading()) {
      super.start("");
      return;
    }

    if (this.model().isStateContentSaving()) {
      this.renderSaving();
      return;
    }

    if (this.model().isStateContentSaved()) {
      this.renderSaved();
      return;
    }

    this.renderHeader();

    if (this.model().isStateContentLoading()) {
      super.start("Loading Checklist Properties");
      return;
    }

    if (this.model().isStateContentLoaded()) {
      this.renderBody();
    }

    this.renderFooter();
  } 

  renderBody() {
    this.addBody(new TitleDisplayRow()
        .withTitle(this.MC.CheckList.Add.Title.value()).withSubtitle(true)
        );
    this.addBody(new RowDisplayRow()
        .withBorder(true)
        .withChildren([
                       new StringUpdateRow()
                          .withWidth(5)
                          .withName(this.MC.CheckList.Add.NameLabel.value())
                          .withObject(Checklist.MODEL_NAME)
                          .withId(this.checklist().id())
                          .withField(Checklist.Fields.Name)
                          .withKey(Checklist.Fields.Name)
                          .withMandatoryValue(true)
                          .withDefaultValue(this.checklist().name())
                          .withHint(this.MC.CheckList.Add.NameHint.value()),
                        
                        new TextAreaUpdateRow()
                          .withWidth(10)
                          .withName(this.MC.CheckList.Add.DescriptionLabel.value())
                          .withObject(Checklist.MODEL_NAME)
                          .withDefaultValue(this.checklist().description())
                          .withId(this.checklist().id())
                          .withField(Checklist.Fields.Description)
                          .withKey(Checklist.Fields.Description)
                          .withHint(this.MC.CheckList.Add.DescriptionHint.value()),
                         
                       ])
        );
    
    this.addBody(new TitleDisplayRow()
        .withTitle(this.MC.CheckList.Add.CheckListItemTitle.value())
        .withSubtitle(true)
        );
    
    var itemChildren = this.createItemChildren();
    var childRows = [];
    childRows.push(new StringDisplayRow()
        .withAlignLeft()
        .withStyleEven()
        .withValue(this.MC.CheckList.QuestionDescription.value()));
    
    childRows.push(...itemChildren);
    
    this.addBody(new BoxDisplayRow()
        .withField(AddViewUtils.Fields.QuestionBox)
        .withBorder(true)
        .withChildren(childRows)
        );
  }

  createSaveAnotherRow() {
    if (this.checklist().isNew()) {
      return new ButtonDisplayRow()
        .withBackgroundPrimaryAction()
        .withName("Save and Add Another")
        .withField("SaveAdd")
        .withWidth(4);
    }
    return new SpacerDisplayRow().withWidth(4);
  }

  renderHeader() {
    this.addHeader(new TitleDisplayRow().withTitle("Add Checklist"));
  }

  renderFooter() {
    
    this.addFooter(
      new RowDisplayRow()
      .withBorder(false)
      .withFixedPosition(true)
      .withChildren([

        new ButtonDisplayRow()
          .withWidth(3)
          .withName("Cancel")
          .withField("Close"),

        new SpacerDisplayRow()
          .withWidth(5),

        this.createSaveAnotherRow(),

        new ButtonDisplayRow()
          .withBackgroundPrimaryAction()
          .withName("Save")
          .withField("Save")
          .withWidth(3),
      ])
    );
  }

  getChecklistItems() {
    return this.checklist().items();
  }
  
  createItemChildren() {
    var kids = [];
    
    var itemMap = this.getChecklistItems();
    var items = itemMap.sortByIndex();
    for (var i = 0 ; i < items.length; i++) {
      var item = items[i];
      
      this.log().info("{0} {1}", [item.index(), item.name()]);
      
      var grandKids = this.createItemOptionChildren(item);
      
      var itemRows = [];
      
      itemRows.push(new ChecklistItemUpdateRow()
           .withItem(item)
           .withIndex(i)
           .withMandatoryValue(true)
           .withMaxIndex(items.length)
           .withChildren(grandKids));
           
      var itemRow = new BoxDisplayRow()
          .withBorder(true)
          .withChildren(itemRows)
      kids.push(itemRow);
    }
    var lastRow = new RowDisplayRow()
      .withChildren([
          new SpacerDisplayRow()
            .withWidth(13),
            
          new ButtonDisplayRow()
            .withWidth(2)
            .withName(this.MC.CheckList.AddQuestionButton.value())
            .withField(AddViewUtils.Fields.AddQuestionButton)
            ]);
    
    kids.push(lastRow);
    return kids;
  }
  
  getChecklistItemOptions(item) {
    return item.options();
  }
  
  createItemOptionChildren(item) {
    var kids = [];
    var optionMap = this.getChecklistItemOptions(item);
    var options = optionMap.sortByIndex();
    
    for (var i = 0 ; i < options.length; i++) {
      var option = options[i];
      var row = new ChecklistItemOptionUpdateRow()
          .withMandatoryValue(true)
          .withIndex(i)
          .withMaxIndex(options.length)
          .withOption(option)
          .withRemove(!item.isTypeBool());
      kids.push(row);
    }
    
    if (item.isTypeList() && !item.isTypeBool()) {
      var lastRow = new RowDisplayRow()
          .withChildren([
              new SpacerDisplayRow()
                .withWidth(13),
                
              new ButtonDisplayRow()
                .withWidth(2)
                .withName(this.MC.CheckList.AddOptionButton.value())
                .withField(AddViewUtils.Fields.AddOptionButton)
                ]);
      kids.push(lastRow);
    }
    
    return kids;
  }
}