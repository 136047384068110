
import RcTableViewModel from "../../../../../../shared/table/RcTableViewModel";
import AddViewUtils from "./AddViewUtils";

import Checklist from "@/domain/model/checklist/CheckList.js";
import ChecklistItem from '@/domain/model/checklist/item/CheckListItem.js';
import ChecklistItemOption from '@/domain/model/checklist/item/option/CheckListItemOption.js';
import ChecklistItemOptionMap from '@/domain/model/checklist/item/option/CheckListItemOptionMap.js';

import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

export default class AddViewModel extends RcTableViewModel {
  
  constructor(panel, checklist) {
    super(panel);
    this._companyId = ContentUtils.baseDecode(panel.$route.params.operatorId);
    this._total = 0;
    this._checklist = checklist;
    this._atLeastOneSaved = false;
  }

  start() {
    this._checklist.populate();
    super.start();
  }

  log() {
    return this.domain().logger();
  }

  isAdmin() {
    return this.panel().isAdmin;
  }

  company() {
    return this.domain().companys().findById(this._companyId);
  }

  withValue(kvp) {
    super.withValue(kvp);

    if (kvp) {
      this.domain().logger().info("Received: {0}", [JSON.stringify(kvp)]);
      
      var objectName = kvp['object'];
      var key = kvp['key'];
      var field = kvp['field'];
      var fieldValue = kvp['value'];
      var objectId = kvp['id'];
      
      if (objectName === Checklist.MODEL_NAME) {
        if (field === Checklist.Fields.Name) {
          this.checklist().withName(fieldValue);
        }
        if (field === Checklist.Fields.Description) {
          this.checklist().withDescription(fieldValue);
        }
      }
      if (objectName === ChecklistItem.MODEL_NAME) {
        var item = this.checklist().items().getChecklistItem(objectId);
        if (field === ChecklistItem.Fields.Name) {
          item.withName(fieldValue);
        }
        
        if (key == ChecklistItem.Fields.Type) {
          item.withType(fieldValue);
          if (item.isTypeBool()) {
            this.doChecklistItemTypeBool(item, kvp);
          } else if (item.isTypeList()) {
            this.doChecklistItemTypeList(item, kvp);
          } else {
            var optionMap = new ChecklistItemOptionMap(this.domain());
            item.withOptions(optionMap);
            this.rebuildTableData();
          }
        }
        
        if (field == ChecklistItem.Fields.MultiSelect) {
          item.withMultiSelect(kvp.value);
        }
      }
    }
    if (objectName === ChecklistItemOption.MODEL_NAME) {
      this.doChecklistItemOptionName(kvp);
    }
    
   this.domain().logger().info("WithValue - Checklist: {0}", [this.checklist().toString()]);
  }
  
  doChecklistItemTypeBool(item, kvp) {
    if (!kvp) {
      return;
    }
    
    var optionMap = item.options();
    if (optionMap.isEmpty()) {
      var yesOption = new ChecklistItemOption(this.domain());
      yesOption
        .withIndex(optionMap.size())
        .withName("Yes")
        .withCategoryGood()
      optionMap.add(yesOption);

      var noOption = new ChecklistItemOption(this.domain());
      noOption
        .withIndex(optionMap.size())
        .withName("No")
        .withCategoryMinor()
        optionMap.add(noOption);
    }
    
    item.withOptions(optionMap);
    this.rebuildTableData();
  }
  
  doChecklistItemTypeList(item, kvp) {
    if (!kvp) {
      return;
    }
    var optionMap = item.options();
    if (optionMap.isEmpty()) {
      var option = new ChecklistItemOption(this.domain());
      option.withCategoryGood();
      option.withIndex(optionMap.size());
      optionMap.add(option);
    }
    item.withOptions(optionMap);
    this.rebuildTableData();
  }
  
  doChecklistItemOptionName(kvp) {
    var parentId = kvp['parentId'];
    var field = kvp['field'];
    var fieldValue = kvp['value'];
    var objectId = kvp['id'];
    var items = this.checklist().items();
    var checklistItem = items.getChecklistItem(parentId);
    var options = checklistItem.options();
    var itemOption = options.getCheckListItemOption(objectId);
    
    if (field === ChecklistItemOption.Fields.Name) {
      itemOption.withName(fieldValue);
    }
    if (field === ChecklistItemOption.Fields.Category) {
      itemOption.withCategory(fieldValue);
    }
    
  }
  
  withButtonPressed(buttonData) {
    super.withButtonPressed(buttonData);
    if (!buttonData) {
      return;
    }
    this.domain().logger().info("Received: {0}", [JSON.stringify(buttonData)]);
    var field = buttonData['field'];
    
    if (field === AddViewUtils.Fields.AddQuestionButton) {
      this.doAddChecklistItemAction(buttonData);
    }
    if (field === AddViewUtils.Fields.RemoveQuestionButton) {
      this.doRemoveChecklistItemAction(buttonData);
    }
    if (field === AddViewUtils.Fields.UpQuestionButton) {
      this.doUpChecklistItemAction(buttonData);
    }
    if (field === AddViewUtils.Fields.DownQuestionButton) {
      this.doDownChecklistItemAction(buttonData);
    }

    if (field === AddViewUtils.Fields.UpOptionButton) {
      this.doUpOptionAction(buttonData);
    }
    if (field === AddViewUtils.Fields.DownOptionButton) {
      this.doDownOptionAction(buttonData);
    }
    if (field === AddViewUtils.Fields.AddOptionButton) {
      this.doAddOptionAction(buttonData);
    }
    if (field === AddViewUtils.Fields.RemoveOptionButton) {
      this.doRemoveOptionAction(buttonData);
    }
    if (buttonData["field"] === "Save") {
      this.doSaveAction();
    }
    if (buttonData["field"] === "SaveAdd") {
      this.doSaveAction(true);
    }
    if (buttonData["field"] === "Close") {
      this.closePage(this);
    }
  }
  
  doUpChecklistItemAction(buttonData) {
    if (!buttonData) {
      return;
    }
    

    var objectId = buttonData['id'];
    var itemMap = this.checklist().items();
    var item = itemMap.getChecklistItem(objectId);
    var nextItem = itemMap.getChecklistItemByIndex(item.index() - 1);
    
    nextItem.withIndex(item.index());
    item.withIndex(item.index() - 1);
    
    itemMap.add(item);
    itemMap.add(nextItem);
    this.checklist().withItems(itemMap);

    this.rebuildTableData();
    
  }
  
  doDownChecklistItemAction(buttonData) {
    if (!buttonData) {
      return;
    }
    
    var objectId = buttonData['id'];
    var itemMap = this.checklist().items();

    var item = itemMap.getChecklistItem(objectId);
    var nextItem = itemMap.getChecklistItemByIndex(item.index() + 1);
    
    nextItem.withIndex(item.index());
    item.withIndex(item.index() + 1);
    
    itemMap.add(item);
    itemMap.add(nextItem);
    
    this.checklist().withItems(itemMap);

    this.rebuildTableData();
  }
  
  doAddChecklistItemAction(buttonData) {
    if (!buttonData) {
      return;
    }
    var itemMap = this.checklist().items();
    var item = new ChecklistItem(this.domain);
    item
      .withIndex(itemMap.size())
      .withTypeString()
      .done();
    itemMap.add(item);
    this.checklist().withItems(itemMap);
    this.rebuildTableData();
  }
  
  doRemoveChecklistItemAction(buttonData) {
    if (!buttonData) {
      return;
    }
    var objectId = buttonData['id'];
    var itemMap = this.checklist().items();
    var item = itemMap.getChecklistItem(objectId);
    itemMap.remove(item);
    
    var sorted = itemMap.sortByIndex(); 
    for (var i = 0; i < sorted.length; i++) {
      item = sorted[i];
      item.withIndex(i);
      itemMap.add(item);
    }
    
    this.checklist().withItems(itemMap);

    this.rebuildTableData();
  }
  
  doUpOptionAction(buttonData) {
    if (!buttonData) {
      return;
    }
    
  }
  
  doDownOptionAction(buttonData) {
    if (!buttonData) {
      return;
    }
  }
  
  doAddOptionAction(buttonData) {
    var itemId = buttonData['parentId'];
    var items = this.checklist().items();
    var item = items.getChecklistItem(itemId).find();
    var optionMap = item.options();
    var option = new ChecklistItemOption(this.domain());
    option
      .withIndex(optionMap.size())
      .withCategoryGood();
    optionMap.add(option);
    item.withOptions(optionMap);
    items.add(item)
    this.checklist().withItems(items)
    this.rebuildTableData();
  }
  
  doRemoveOptionAction(buttonData) {
    if (!buttonData) {
      return;
    }
    
    var itemId = buttonData['parentId'];
    var itemMap = this.checklist().items();
    var item = itemMap.getChecklistItem(itemId)
    var optionMap = item.options();
    
    var optionId = buttonData['id'];
    var option = optionMap.getCheckListItemOption(optionId);
    optionMap.remove(option);
    
    var sorted = optionMap.sortByIndex(); 
    for (var i = 0; i < sorted.length; i++) {
      option = sorted[i];
      option.withIndex(i);
      optionMap.add(option);
    }
    
    item.withOptions(optionMap);
    this.rebuildTableData();
  }

  resetPage(model) {
    model._checklist = new Checklist(model.domain());
    model.withStateContentLoaded();
    model.panel().reloadPage();
  }
  
  closePage(model) {
    model.panel().close(model._atLeastOneSaved);
  }

  buildRowData(tableData) {
    var utils = new AddViewUtils(this, tableData);
    utils.start();
    return this;
  }

  loadData() {
    super.loadItems();
    this
      .withStateContentLoading()
      .clearAndBuildTableData()
      .done();

    this.log().info("Loading view data");
    var context = {};
    context["model"] = this;

    var response = {};
    this.loadedData(response, context);
  }

  loadedData(response, context) {
    if (response || context) {
      //
    }
    var model = context.model;
    model.log().info("Loaded view data");

    model
      .withStateContentLoaded()
      .clearAndBuildTableData()
      .done();
  }

  checklist() {
    return this._checklist;
  }

  areRequirementsMet() {
    var met = true;
    var checklist = this.checklist();
    if (StringUtils.isEmpty(checklist.name())) {
      met = false;
    }
    var items = checklist.items();
    var itemKeys = items.keys();
    for (var i = 0; i < itemKeys.length; i++) {
      var item = items.getChecklistItem(itemKeys[i]);
      if (StringUtils.isEmpty(item.name())) {
        met = false;
        break;
      }
      var options = item.options();
      var optionKeys = options.keys();
      for (var j = 0; j < optionKeys.length; j++) {
        var option = options.getCheckListItemOption(optionKeys[j]);
        if (StringUtils.isEmpty(option.name())) {
          met = false;
          break;
        }
      }
    }
    return met;
  }

  doSaveAction(addAnother) {
    if (!this.areRequirementsMet()) {
      this.domain().logger().info("Requirements not met!");
      this.panel().showMissingRequirements = true;
      return;
    }
    
    this.rebuildTableData();
    
    var event = this.domain()
        .events()
        .checklists().save(this.checklist());

    var context = {};
    context.model = this;
    context.addAnother = addAnother;
    event.send(this.doSaveActionListener, context);
  }

  doSaveActionListener(response, context) {
    var model = null;
    if (!context) {
      return;
    }
    model = context.model;

    model._isSaving = false;
    model.panel().showSavingDialog = false;

    if (response.hasError()) {
      model.withError(response.error());
      model.withStateContentLoaded();
      model.clearAndBuildTableData();
    } else {
      if (context.addAnother) {
        model.withStateContentSaved();
        model.panel().reloadPage();
        model._atLeastOneSaved = true;
        model.log().info("Checklist saved, adding another");
        setTimeout(function() {
          model.resetPage(model);
        }, 500);
      } else {
        model._atLeastOneSaved = true;
        model.withStateContentSaved();
        model.panel().reloadPage();
    
        model.log().info("Checklist saved, done");
        setTimeout(function() {
          model.closePage(model);
        }, 500);
      }
    }
  }
}