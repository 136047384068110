
import RcTableViewModel from "../../../../../../shared/table/RcTableViewModel";

import DisplayViewUtils from "./DisplayViewUtils";
import Checklist from "@/domain/model/checklist/CheckList.js";

import ContentUtils from '@/utils/ContentUtils.js';

export default class DisplayViewModel extends RcTableViewModel {
  
  constructor(panel, checklist) {
    super(panel);
    this._companyId = ContentUtils.baseDecode(panel.$route.params.operatorId);
    this._total = 0;
    this._checklist = checklist;
  }

  start() {
    this._checklist.populate();
    super.start();
  }

  log() {
    return this.domain().logger();
  }

  isAdmin() {
    return this.panel().isAdmin;
  }

  company() {
    return this.domain().companys().findById(this._companyId);
  }

  withValue(kvp) {
    super.withValue(kvp);
  }
  
  withButtonPressed(buttonData) {
    super.withButtonPressed(buttonData);
    if (buttonData["field"] === "Close") {
      this.closePage(this);
    }
  }
  
  resetPage(model) {
    model._checklist = new Checklist(model.domain());
    model.withStateContentLoaded();
    model.panel().reloadPage();
  }
  
  closePage(model) {
    model.panel().close(model._atLeastOneSaved);
  }

  buildRowData(tableData) {
    var utils = new DisplayViewUtils(this, tableData);
    utils.start();
    return this;
  }

  loadData() {
    super.loadItems();
    this
      .withStateContentLoading()
      .clearAndBuildTableData()
      .done();

    this.log().info("Loading view data");
    var context = {};
    context["model"] = this;

    var response = {};
    this.loadedData(response, context);
  }

  loadedData(response, context) {
    if (response || context) {
      //
    }
    var model = context.model;
    model.log().info("Loaded view data");

    model
      .withStateContentLoaded()
      .clearAndBuildTableData()
      .done();
  }

  checklist() {
    return this._checklist;
  }

}