

import AddViewUtils from "../add/AddViewUtils";

import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";

export default class UpdateViewUtils extends AddViewUtils {

  constructor(model, tableData) {
    super(model, tableData);
  }

  start() {
    super.start();
  }

  renderHeader() {
    this.addHeader(new TitleDisplayRow().withTitle("Update Checklist: " + this.model().checklist().name()));
  }

  getChecklistItems() {
    var items = super.getChecklistItems();
    return items;
  }

  getChecklistItemOptions(item) {
    var options = super.getChecklistItemOptions(item);
    return options;
  }
}